import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL, STATIC_SERVER_URL } from "../../../config/config";
import { Carousel } from "nuka-carousel";

import "./hero.scss";

const Hero = () => {
  const [banners, setBanners] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${API_BASE_URL}/banner/show`)
      .then((res) => res.json())
      .then(
        (data) => {
          if (Array.isArray(data)) {
            setBanners(data);
          } else {
            setBanners([data]);
          }
        },
        (err) => {
          setError(err);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Carousel
      showDots={true}
      autoplay={true}
      autoplayInterval={4000}
      animation="slide"
      slidesToShow={1}
      wrapMode="wrap"
    >
      {banners.map((banner, index) => (
        <Link to={banner.url} key={index}>
          <img
            src={`${STATIC_SERVER_URL}/web/images/shop/${banner.image_desktop}`}
            alt={banner.title}
          />
        </Link>
      ))}
    </Carousel>
  );
};

export default Hero;
